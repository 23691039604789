import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-20">
          I am a Computer Science and Engineering major with a minor in math and Unmanned Autonomous systems.
          I am interested in working in the field of AI and Data Science. 
          Currently, I am working as a Tech Support at Clear Capital, where I help people navigate and troubleshoot the problems they encounter within the app called ClearInsight.
          I am currently looking for opportunities to develop my Software Engineering skills.
        </p>

        <br />

        <p className="text-xl">
          I am a hard worker and I value constructive criticism. I thrive in working under pressure and working with teams. I love trying out new things and learning more about them and
          I always try to be great at everything I do.
        </p>
      </div>
    </div>
  );
};

export default About;
